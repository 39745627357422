import * as React from 'react'
import { Theme, Grid, Typography, IconButton } from '@mui/material'
import { Twitter, Instagram } from '@mui/icons-material'

import { ReactComponent as EtsyIcon } from 'Assets/svg/EtsyIcon.svg'
import { ReactComponent as DiscordIcon } from 'Assets/svg/DiscordIcon.svg'
import { ReactComponent as TikTokIcon } from 'Assets/svg/TikTokIcon.svg'

const styles = {
  container: (theme: Theme) => ({
    display: 'flex',
    height: '3em',
    // marginTop: '1em',
    backgroundColor: theme.palette.primary.main,
    alignContent: 'center',
    justifyContent: 'center'
  }),
  spacer: {
    height: '2em'
  },
  svg: {
    height: '1em',
    width: '1em'
  },
  logo: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  }),
  socials: {
    display: 'flex',
    alignItems: 'center'
  },
  text: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  })
}

type Props = {}

const Footer: React.FC<Props> = () => {
  const twitterUrl = 'https://twitter.com/jk_charms?lang=en'
  const instagramUrl = 'https://www.instagram.com/jk.charms/?hl=en'
  const etsyUrl = 'https://www.etsy.com/shop/JKCharms1'
  const discordUrl = 'https://discord.gg/54CkSYeqMD'
  const tiktokUrl = 'https://www.tiktok.com/@jkcharms?'

  return (
    <>
      {/* <div style={styles.spacer}></div> */}
      <Grid container sx={styles.container}>
        <Grid item sx={styles.socials}>
          <Typography variant='subtitle1' sx={styles.text}>
            Socials / Store
          </Typography>
          <IconButton onClick={() => window.open(twitterUrl, '_blank')}>
            <Twitter sx={styles.svg} />
          </IconButton>
          <IconButton onClick={() => window.open(instagramUrl, '_blank')}>
            <Instagram sx={styles.svg} />
          </IconButton>
          <IconButton onClick={() => window.open(discordUrl, '_blank')}>
            <DiscordIcon style={styles.svg} />
          </IconButton>
          <IconButton onClick={() => window.open(etsyUrl, '_blank')}>
            <EtsyIcon style={styles.svg} />
          </IconButton>
          <IconButton onClick={() => window.open(tiktokUrl, '_blank')}>
            <TikTokIcon style={styles.svg} />
          </IconButton>
        </Grid>
        <Grid item sx={styles.logo}>
          <Typography variant='subtitle1'>@JK Charms</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Footer
