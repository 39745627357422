import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useRecoilState, useRecoilValue } from 'recoil'
import { klona } from 'klona'
import dayjs from 'dayjs'

import {
  cartState,
  cartItems,
  numOfItems,
  currency,
  prodState,
  authState,
  basicEventState
} from 'State'
import { ProductItem, Product, BasicEvent } from 'Types'

export const useCartState = () => {
  const [state, setState] = useRecoilState(cartState)
  const [items, setItems] = useRecoilState(cartItems)
  const numOfCartItems = useRecoilValue(numOfItems)

  const addItemsToCart = (
    newState: ProductItem[],
    newItem: ProductItem,
    currentProds: string[]
  ) => {
    if (currentProds.includes(newItem.productId)) {
      newState.forEach((item, i, arr) => {
        if (item.productId === newItem.productId) {
          arr[i].amount += newItem.amount

          if (!arr[i].amount) arr.splice(i, 1)
        }
      })
    } else if (newItem.amount >= 0) newState.push(newItem)
  }

  const setTypes = (newItem: ProductItem | ProductItem[]) => {
    if (Array.isArray(newItem)) {
      newItem.forEach((item, i, arr) => {
        const typedItem: ProductItem = {
          ...item,
          productId: item.productId.toString(),
          amount: +item.amount
        }
        arr[i] = typedItem
      })

      return newItem
    } else return { ...newItem, productId: newItem.productId.toString(), amount: +newItem.amount }
  }

  const setStateToLS = (newState: ProductItem[]) => {
    if (!newState.length) return localStorage.removeItem('cart')

    localStorage.removeItem('cart')
    localStorage.setItem('cart', JSON.stringify(newState))
  }

  const setCartItems = (newItem: ProductItem | ProductItem[]) => {
    setItems(prev => {
      const newState: ProductItem[] = JSON.parse(JSON.stringify(prev))
      const typedItem = setTypes(newItem)
      const currentProds = newState.map(items => items.productId)

      // If there is multiple items coming in
      if (Array.isArray(typedItem))
        if (!newState.length)
          // If there is no current items in the cart
          return typedItem
        // If there is then merge them
        else for (const item of typedItem) addItemsToCart(newState, item, currentProds)
      // If only one item coming in merge them
      else addItemsToCart(newState, typedItem, currentProds)

      setStateToLS(newState)
      return newState
    })
  }

  return {
    state,
    items,
    setState,
    // cartData,
    setCartItems,
    numOfCartItems
  }
}

export const useProductState = () => {
  const [productList, setProductList] = useRecoilState(prodState)

  const setTypes = (newItem: Product | Product[]) => {
    if (Array.isArray(newItem)) {
      newItem.forEach((item, i, arr) => {
        const typedItem: Product = {
          ...item,
          productId: item.productId.toString()
        }
        arr[i] = typedItem
      })

      return newItem
    } else return { ...newItem, productId: newItem.productId.toString() }
  }

  const addProd = (product: Product | Product[]) => {
    setProductList(prev => {
      const newList: Product[] = JSON.parse(JSON.stringify(prev))
      const currProds = newList.map(prod => prod.productId)
      const typedProd = setTypes(product)

      if (Array.isArray(typedProd))
        for (const prod of typedProd) {
          if (!currProds.includes(prod.productId)) newList.push(prod)
        }
      else if (!currProds.includes(typedProd.productId)) newList.push(typedProd)
      return newList
    })
  }

  const addImagesToProd = (images: Blob[], productId: string) => {
    setProductList(prev => {
      const newList: Product[] = klona(prev)
      newList.forEach(prod => {
        if (prod.productId === productId) prod.images.push(...images)
      })
      return newList
    })
  }

  return {
    productList,
    addProd,
    addImagesToProd
  }
}

export const useCurrencyState = () => {
  const curr = useRecoilValue(currency)

  return {
    currency: curr
  }
}

export const useAuthState = () => {
  const [state, setState] = useRecoilState(authState)
  const checkIfSignedIn = async () => {
    const auth = getAuth()

    const waitForAuthInit = async () => {
      let unsubscribe: any
      await new Promise<void>(resolve => {
        unsubscribe = onAuthStateChanged(auth, _ => resolve())
      })
      ;(await unsubscribe!)()
    }

    await waitForAuthInit()
    const user = auth.currentUser

    if (!user || !auth.currentUser.email) return setState({ isSignedIn: false })
    const idToken = await getAuth().currentUser?.getIdToken(true)
    // REPLACE THIS
    // const res = await get<Todo[]>(`/todo`, {
    //   headers: { authorization: `Bearer: ${idToken}` },
    // });

    // todoSetState(res.data);
    setState({ isSignedIn: true, idToken, userData: { email: auth.currentUser.email } })
  }

  return { authState: state, checkIfSignedIn }
}

export const useEventState = () => {
  const [state, setState] = useRecoilState(basicEventState)

  const convertBasicToDayJs = (events: BasicEvent[]) => {
    events.forEach(event => {
      if (event.eventData.title) event.eventData.title.date = dayjs(event.eventData.title.date)
    })

    return events
  }

  const getFeaturedEvents = () => {
    const featuredEvents: BasicEvent[] = []

    if (!state.length) return undefined

    for (let event of state) {
      if (event.status === 'featured') featuredEvents.push(event)
    }
    return featuredEvents
  }

  return { eventState: state, setEventState: setState, getFeaturedEvents, convertBasicToDayJs }
}
