import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid
} from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'

import { TextField } from 'Components/Form'
import { useAxios, useAlert } from 'Hooks'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  urlName: string
  description: string
}

type Form = {
  name: string
  email: string
  igHandle: string
}

const styles = {
  fields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

const RsvpModal: React.FC<Props> = ({ open, setOpen, urlName, description }) => {
  const [loading, setLoading] = React.useState(false)
  const { setAlertStatus } = useAlert()
  const { patch } = useAxios()
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: { email: '', name: '', igHandle: '' },
    reValidateMode: 'onChange'
  })

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit: SubmitHandler<Form> = async ({ email, name, igHandle }) => {
    try {
      setLoading(true)
      const { data } = await patch(`/user/rsvp`, { rsvp: urlName, email, name, igHandle })
      console.log({ data })
      setAlertStatus({ severity: 'success', msg: data as string })
      setLoading(false)
      handleClose()
    } catch (e) {
      setLoading(false)
      setAlertStatus({
        severity: 'error',
        msg: 'Failed to get extra entry, please contact support!'
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle variant='h4' textAlign='center'>
        Extra Door Prize Entry.
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText textAlign='center'>
            <Typography variant='h5'>{description}</Typography>
          </DialogContentText>
          <Grid
            spacing={1}
            marginTop='1em'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            container
          >
            <TextField
              fieldName='email'
              label='Email'
              control={control}
              gridProps={{ xs: 12, md: 8 }}
              sx={styles.fields}
              controllerProps={{
                rules: {
                  required: 'Email is required.',
                  validate: {
                    maxLength: v => v.length <= 50 || 'The email should have at most 50 characters',
                    matchPattern: v =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'Email address must be valid.'
                  }
                }
              }}
            />

            <TextField
              fieldName='name'
              label='Full Name'
              control={control}
              gridProps={{ xs: 12, md: 8 }}
              sx={styles.fields}
              controllerProps={{
                rules: {
                  required: 'Full name is required.',
                  minLength: { value: 3, message: 'Full name must be more than 3 characters' }
                }
              }}
            />

            <TextField
              fieldName='igHandle'
              label='Instagram Handle'
              control={control}
              gridProps={{ xs: 12, md: 8 }}
              InputProps={{ startAdornment: '@' }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={!!errors.email || loading} variant='contained' type='submit'>
            Submit
          </Button>
          <Button variant='contained' color='error' onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RsvpModal
