import * as React from 'react'
import { Grid, Theme, Typography } from '@mui/material'

import { EventHighLights } from 'Types'

const styles = {
  list: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      marginBottom: 4
    },

    [theme.breakpoints.up('md')]: {
      margin: 4
    }
  })
}

const Highlights: React.FC<EventHighLights> = ({ items, variant = 'h5', xs = 12, md = 6, lg, xl }) => {
  return (
    <Grid item xs={11} md={8} sx={{ textAlign: 'left' }}>
      <Grid container sx={styles.list}>
        {items.map(item => (
          <Grid key={item.highlight} item xs={xs} md={md} lg={lg} xl={xl}>
            <Typography variant={variant}>{item.highlight}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Highlights
