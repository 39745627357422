const styles = {
  container: {
    marginTop: '2em'
  },
  mainImageContainer: {
    height: '18em',
    justifyContent: 'center',
    position: 'relative'
  },
  mainImage: {
    height: 'inherit',
    width: 'inherit',
    borderRadius: '9px'
  },
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    // Not sure if capitalizing these is correct
    flexDirection: 'column',
    height: '18em',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0',
      height: '0'
    }
  },
  previewImage: {
    height: '5em',
    width: '5em',
    borderRadius: '9px',
    margin: '0.5em 1em',
    flexWrap: 'nowrap' as 'nowrap',
    '&:hover, &:focus': {
      boxShadow: '0 2px 18px rgb(34 34 34 / 20%)'
    }
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    bottom: '1px'
  },
  controlDotsContainer: {
    display: 'flex',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0',
      height: '0'
    }
  },
  controlButtons: {
    color: 'white'
  }
}

export default styles
