import * as React from 'react'
import {
  Typography,
  AppBar,
  Toolbar,
  // IconButton,
  // CircularProgress,
  // Badge,
  // Button,
  Theme,
  Box
} from '@mui/material'
// import { ShoppingBasket } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import AuthModal from 'Components/AuthModal'
// import ShoppingCart from 'Components/ShoppingCart'
// import { useCartState, useFirebase, useAuthState } from 'Hooks'
// import { ProductItem } from 'Types'

const styles = {
  root: {
    flexGrow: 1
  },
  appBar: {
    display: 'flex',
    position: 'relateive'
  },
  menuButton: (theme: Theme) => ({
    // marginRight: theme.spacing(2)
    position: 'absolute',
    right: '6vw'
    // top: 0,
  }),
  title: (theme: Theme) => ({
    flex: '1 0 auto',
    color: '#d1f9d1',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em'
    }
  }),
  link: {
    textDecoration: 'none',
    color: '#d1f9d1'
  }
}

type Props = {}

const NavBar: React.FC<Props> = () => {
  const [open, setOpen] = React.useState<boolean>(false)
  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const { setCartItems } = useCartState()
  // const { signOutUser } = useFirebase()
  // const { authState } = useAuthState()

  // React.useEffect(() => {
  //   const storageState = localStorage.getItem('cart')
  //   if (!storageState) return
  //   const parsedState: ProductItem | ProductItem[] = JSON.parse(storageState)
  //   setCartItems(parsedState)
  // }, [])

  return (
    <Box sx={styles.root}>
      <AppBar position='static' sx={styles.appBar}>
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"> */}
          {/* <MenuIcon /> */}
          {/* </IconButton> */}
          <Typography variant='h2' sx={styles.title}>
            <Link to={`/`} style={styles.link}>
              JK Charms
            </Link>
          </Typography>
          {/* Disabling all this untill store is finished */}
          {/* {!authState.isSignedIn ? (
            <Button
              variant='contained'
              color='secondary'
              sx={styles.menuButton}
              onClick={() => setOpen(true)}
            >
              <Typography variant='body2'>Login/SignUp</Typography>
            </Button>
          ) : (
            <>
              <Typography variant='h5'>Hi {authState.userData?.email}</Typography>
              <Button variant='contained' onClick={() => signOutUser()}>
                SignOut
              </Button>
            </>
          )}

          <React.Suspense
            fallback={
              <IconButton>
                <Badge
                  badgeContent={<CircularProgress color='primary' size={10} />}
                  color='secondary'
                >
                  <ShoppingBasket />
                </Badge>
              </IconButton>
            }
          >
            <ShoppingButton setAnchorEl={setAnchorEl} />
          </React.Suspense> */}
        </Toolbar>
      </AppBar>
      {/* <React.Suspense fallback={null}>
        <ShoppingCart anchorEl={anchorEl} />
      </React.Suspense> */}
      <AuthModal open={open} setClose={() => setOpen(false)} />
    </Box>
  )
}

// type ShoppingButtonProps = {
//   setAnchorEl: React.Dispatch<React.SetStateAction<any>>
// }

// const ShoppingButton: React.FC<ShoppingButtonProps> = ({ setAnchorEl }) => {
//   const { setState, numOfCartItems } = useCartState()

//   const handleClick = (event: any) => {
//     setAnchorEl(event.currentTarget)
//     setState(prev => !prev)
//   }

//   return (
//     <IconButton onClick={handleClick}>
//       <Badge badgeContent={numOfCartItems} color='secondary'>
//         <ShoppingBasket />
//       </Badge>
//     </IconButton>
//   )
// }

export default NavBar
