import { Grid, styled, Typography } from '@mui/material'

import InlineButton from 'Components/InlineButton'
import { EventTitle } from 'Types'

const SubTitle = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'baseline'
}))

const Title: React.FC<EventTitle> = ({ name, date, creators, description }) => {
  const creatorArray: any[] = [
    <Typography key={'insert'} variant='h6'>
      Hosted by:
    </Typography>
  ]

  creators.forEach((creator, i) => {
    if (i !== 0) {
      if (i === creators.length - 1)
        creatorArray.push(
          <Typography key={creator.name + '-insert'} variant='h6'>
            and
          </Typography>
        )
      else
        creatorArray.push(
          <Typography key={creator.name + 'insert'} variant='h6'>
            ,
          </Typography>
        )
    }

    creatorArray.push(
      <InlineButton
        key={creator.name}
        label={creator.name}
        url={creator.url}
        color={creator.color}
        variant='h6'
      />
    )
  })

  return (
    <Grid item xs={11} md={8} sx={{ textAlign: 'left' }}>
      <Typography variant='subtitle1'>{date.format('MMMM DD YYYY')}</Typography>
      <Typography variant='h3'>{name}</Typography>
      <SubTitle>{creatorArray}</SubTitle>
      <Typography variant='h5'>{description}</Typography>
    </Grid>
  )
}

export default Title
