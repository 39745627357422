import { Grid, styled } from '@mui/material'
import Zoom from 'react-medium-image-zoom'
import dayjs from 'dayjs'

import Title from './Title'
import Highlights from './Highlights'
import Text from './Text'
import Vendors from './Vendors'
import TimeLocation from './Location'
import Map from './Map'
import EventTicket from 'Components/EventTicket'

import { EventImage, EventZoom, BackendEvent } from 'Types'

type ImageOrientation = {
  orientation: 'vertical' | 'horizontal'
}
const Image = styled('img', {
  shouldForwardProp: prop => prop !== 'orientation'
})<ImageOrientation>(({ theme, orientation = 'horizontal' }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: orientation === 'vertical' ? '75vh' : null,
    maxWidth: orientation === 'horizontal' ? '75vw' : null
  }
}))

const ImageZoom: React.FC<EventZoom> = ({ image, alt, orientation = 'horizontal' }) => {
  return (
    <Grid item xs={10} md={8} lg={7} sx={{ mt: 3 }}>
      <Zoom>
        <Image src={image} alt={alt} style={{ maxHeight: '50vh' }} orientation={orientation} />
      </Zoom>
    </Grid>
  )
}

const ImageBanner: React.FC<EventImage> = ({ image, alt }) => {
  return (
    <Grid item xs={11} md={8} sx={{ mt: 3 }}>
      <img src={image} alt={alt} width='100%' />
    </Grid>
  )
}

const mapEventItems = ({ sections }: BackendEvent, cb: () => void) => {
  return sections.map(section => {
    if (section.type === 'eventTicket') {
      const { type, ...props } = section
      return {
        type,
        props: {
          ...props,
          cb
        }
      }
    } else if (section.type === 'title') {
      const { type, ...props } = section
      return {
        type,
        props: {
          ...props,
          date: dayjs(props.date)
        }
      }
    } else if (section.type === 'timeLocation') {
      const { type, ...props } = section
      return {
        type,
        props: {
          ...props,
          time: {
            dates: props.time.dates.map(({ date, endTime, startTime }) => ({
              date: dayjs(date),
              startTime: dayjs(startTime),
              endTime: dayjs(endTime)
            })),
            hours: section.time.hours
          }
        }
      }
    } else {
      const { type, ...props } = section
      return {
        type,
        props
      }
    }
  })
}

const mapping = {
  banner: ImageBanner,
  zoomImage: ImageZoom,
  title: Title,
  highlights: Highlights,
  text: Text,
  vendors: Vendors,
  timeLocation: TimeLocation,
  map: Map,
  eventTicket: EventTicket
}

export { Image, ImageZoom, ImageBanner, mapping, mapEventItems }
