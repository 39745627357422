import * as React from 'react'
import { Grid, styled } from '@mui/material'
import GoogleMapReact from 'google-map-react'

import googleMapsAPIKey from 'Utils/GoogleMaps'
import { EventMap } from 'Types'

const Marker = styled('div')(() => ({
  color: 'white',
  background: 'grey',
  padding: '15px 10px',
  display: 'inline-flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  transform: 'translate(-50%, -50%)'
}))

const GoogleMapsMarker = ({ text }: { text: string; lat: number; lng: number }) => (
  <Marker>{text}</Marker>
)

const Map: React.FC<EventMap> = ({ lat, lng, zoom = 14, markerText }) => {
  const defaultProps = {
    center: {
      lat,
      lng
    },
    zoom
  }

  const { apiKey } = googleMapsAPIKey
  return (
    <Grid item xs={11} md={8} height='40em'>
      {apiKey ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <GoogleMapsMarker
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
            text={markerText}
          />
        </GoogleMapReact>
      ) : null}
    </Grid>
  )
}

export default Map
