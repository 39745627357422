import { Button, Theme, Typography } from '@mui/material'
import * as React from 'react'

type Props = {
  url: string
  label: string
  openNewTab?: boolean
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
  color?: string
}

const InlineButton: React.FC<Props> = ({
  openNewTab = true,
  variant = 'h5',
  url,
  label,
  color
}) => {
  if (!url.toLocaleLowerCase().includes('https://')) url = 'https://' + url

  const styles = {
    button: (theme: Theme) => ({
      textTransform: 'none',
      color: color || theme.palette.primary.dark
    })
  }

  return (
    <Button
      variant='text'
      target={openNewTab ? '_target' : undefined}
      component='a'
      href={url}
      sx={styles.button}
    >
      <Typography variant={variant}>{label}</Typography>
    </Button>
  )
}

export default InlineButton
