import * as React from 'react'

import { Grid, CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'

import { useAxios, useProductState } from 'Hooks'
import ImageCarousel from 'Components/ProductCarousel'
import ProductDetails from 'Components/ProductDetails'
import { mockImages } from 'Views/mockData'
import { Product } from 'Types'

// const useStyles = makeStyles((theme: any) => ({}))

type Props = {}

const ProductPage: React.FC<Props> = () => {
  // const classes = useStyles()
  const [product, setProduct] = React.useState<Product>()
  const { productList, addProd } = useProductState()
  const { get } = useAxios()
  const { productId } = useParams<any>()

  const getProducts = React.useCallback(async () => {
    try {
      const currProds = productList.map(prod => prod.productId)
      if (currProds.includes(productId.toString()))
        setProduct(productList[currProds.indexOf(productId.toString())])
      else {
        const { data } = await get<Product>(`/products/${productId}`)
        console.log('im culprit productpGE')
        addProd(data)
        setProduct(data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [get, productId, addProd, productList])

  React.useEffect(() => {
    if (!product) getProducts()
  }, [getProducts, product])

  return (
    <Grid item container>
      {/* <Typography variant='h6'>This is product: {productId}</Typography> */}
      <Grid item xs={5}>
        <ImageCarousel images={mockImages} />
      </Grid>
      <Grid item xs={7}>
        {!product ? <CircularProgress /> : <ProductDetails product={product} />}
      </Grid>
    </Grid>
  )
}

export default ProductPage
