import MusicBox from 'Assets/Products/music-box.jpg'

export const mockData = [
  {
    image: 'https://via.placeholder.com/150',
    prodDesc: 'Testing',
    prodId: 1,
    price: 20
  },
  {
    image: MusicBox,
    prodDesc: 'Monsta X Music Box',
    prodId: 2,
    price: 20
  },
  {
    image: null,
    prodDesc: 'Testing',
    prodId: 3,
    price: 20
  },
  {
    image: null,
    prodDesc: 'Testing',
    prodId: 4,
    price: 20
  },
  {
    image: null,
    prodDesc: 'Testing',
    prodId: 5,
    price: 20
  }
]

export const mockImages = [
  MusicBox,
  'https://via.placeholder.com/150',
  'https://via.placeholder.com/250',
  'https://via.placeholder.com/500',
  'https://via.placeholder.com/100',
  'https://via.placeholder.com/200',
  'https://via.placeholder.com/400',
  'https://via.placeholder.com/300'
]
