import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { useEventState, useAxios } from 'Hooks'
import EventCard from 'Components/EventCard'
import CarouselItem from 'Components/BannerCarouselItem'
import { BasicEvent } from 'Types'

type Props = {}

const styles = {
  container: {
    marginTop: '2em'
  },
  carousel: {
    height: 'inherit'
  },
  events: {
    display: 'flex'
  }
}

const EventsPage: React.FC<Props> = () => {
  const { eventState, setEventState, getFeaturedEvents, convertBasicToDayJs } = useEventState()
  const { get } = useAxios()

  React.useEffect(() => {
    const getBannerEvents = async () => {
      try {
        console.log('getting events')
        const { data } = await get<BasicEvent[]>('events')
        console.log({ data })
        setEventState(convertBasicToDayJs(data))
      } catch (e) {
        console.log({ e })
      }
    }

    if (!eventState.length) getBannerEvents()
  }, [get, eventState, setEventState, convertBasicToDayJs])

  const determineEvent = (timeFrame: 'upcoming' | 'past') => {
    if (!eventState.length) return null

    return eventState.map(event => {
      if (!event.eventData.title) return null
      const eventDate = event.eventData.title.date

      const isAfterOrBefore =
        timeFrame === 'upcoming'
          ? eventDate.isAfter(new Date()) || eventDate.isSame(new Date())
          : eventDate.isBefore(new Date())

      if (isAfterOrBefore) {
        return (
          <Grid
            item
            key={event.urlName}
            xs={12}
            md={3}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={styles.events}
          >
            <EventCard
              name={event.eventData.title.name}
              image={event.eventData.thumbnail}
              description={event.eventData.title.description}
              url={`/events/${event.urlName}`}
            />
          </Grid>
        )
      } else return null
    })
  }

  return (
    <Grid container spacing={2} sx={styles.container} justifyContent='center'>
      <Grid item xs={11} sx={styles.carousel}>
        <Carousel showThumbs={false} showStatus={false}>
          {eventState.length
            ? getFeaturedEvents()?.map(event => (
                <CarouselItem
                  key={event.urlName}
                  image={event.eventData.banner.image}
                  url={`/events/${event.urlName}`}
                  alt={event.eventData.banner.alt}
                />
              ))
            : []}
        </Carousel>
      </Grid>
      <Grid item xs={11}>
        <Typography variant='h2'>Upcoming Events:</Typography>
      </Grid>
      {determineEvent('upcoming')}
      <Grid item xs={11}>
        <Typography variant='h2'>Past Events:</Typography>
      </Grid>
      {determineEvent('past')}
    </Grid>
  )
}

export default EventsPage
