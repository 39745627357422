import { Box } from '@mui/material'
import * as React from 'react'

type TicketRipProps = {
  classes: {
    left: any
    sheet: any
    tear: any
    right: any
  }
}

const VerticalTicketRip: React.FC<TicketRipProps> = ({ classes }) => (
  <>
    <Box sx={classes.left} className={'moveLeft'}>
      <Box sx={classes.sheet}></Box>
    </Box>
    <Box sx={classes.right} className={'moveRight'}>
      <Box sx={classes.sheet}>
        <Box sx={classes.tear} />
      </Box>
    </Box>
  </>
)

export default VerticalTicketRip
