import * as React from 'react'
import { Grid, Theme } from '@mui/material'
import 'react-medium-image-zoom/dist/styles.css'
import { useLocation } from 'react-router-dom'

import { mapping, mapEventItems } from './helpers'
import { useAuthState, useAxios, useAlert } from 'Hooks'
import { EventPageItemProps, BackendEvent, BasicEvent, Auth } from 'Types'
import RsvpModal from './rsvpModal'

type Props = {}

const EventPage: React.FC<Props> = () => {
  const [pageItems, setPageItems] = React.useState<EventPageItemProps>()
  const [details, setDetails] = React.useState<BasicEvent>()
  const [open, setOpen] = React.useState<boolean>(false)
  const { setAlertStatus } = useAlert()
  const { patch, get } = useAxios()
  const { authState } = useAuthState()
  // Authstate gets passed into a callback so need useRef to keep the state from going stale
  const authRef = React.useRef<Auth>()
  authRef.current = authState
  const location = useLocation()
  const eventName = location.pathname.split('events/')[1]

  const styles = {
    container: (theme: Theme) => ({
      backgroundColor: details && details.eventData.bg.colour,
      [theme.breakpoints.down('md')]: details?.eventData.bg.mobile && {
        backgroundImage: details && `url(${details?.eventData.bg.mobile})`,
        backgroundSize: 'contain repeat-y'
      },
      [theme.breakpoints.up('md')]: details?.eventData.bg.full && {
        background: details && `url(${details?.eventData.bg.full})`,
        backgroundSize: 'contain'
      }
    })
  }

  const rsvp = React.useCallback(async () => {
    try {
      const results = await patch(`/events/rsvp/${eventName}`, {})
      if (results.status === 200) {
        setAlertStatus({ severity: 'success', msg: results.data as string })
      }
      // Add more checking for different statuses
    } catch (e) {
      setAlertStatus({ severity: 'error', msg: 'Failed to RSVP please contact support' })
    }
  }, [eventName, patch, setAlertStatus])

  React.useEffect(() => {
    const getEvent = async () => {
      try {
        const event = await get<BackendEvent>(`events/${eventName}`)
        const { urlName, sections, eventData, status } = event.data
        if (!urlName || !sections || !eventData) throw new Error('Issue with loading Data')

        const eventPageItems = mapEventItems(event.data, () => {
          // Removing the need to login to rsvp logic
          // if (authRef.current?.isSignedIn && authRef.current?.idToken) {
          //   rsvp(authRef.current.idToken)
          // } else {
          //   setOpen(true)
          // }
          rsvp()
          setOpen(true)
        })

        if (!pageItems) setPageItems(eventPageItems as EventPageItemProps)
        if (!details) setDetails({ urlName, eventData, status })
      } catch (e) {
        console.log(e)
      }
    }

    if (!pageItems || !eventName) getEvent()
  }, [location, get, pageItems, setPageItems, details, authState, eventName, rsvp])

  // This should be coming from the backend and set on event creation form
  const description = `Thank you for Rsvp'ing. Admission is free and Rsvp just helps us get a grasp on number of attendees, Enter details to get an extra entry when you scan the door prize qr code at the event.`

  return (
    <Grid container spacing={2} justifyContent='center' sx={styles.container}>
      {pageItems &&
        pageItems.map((item, i) => {
          const Component = mapping[item.type]
          // @ts-ignore this is kinda sketchy but the typing should be keeping it secure enough
          return <Component key={item.type + '-' + i} {...item.props} />
        })}
      <RsvpModal open={open} setOpen={setOpen} urlName={eventName} description={description} />
      {/* <AuthModal open={open} setClose={() => setOpen(false)} cb={rsvp} /> */}
    </Grid>
  )
}

export default EventPage
