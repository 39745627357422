import React from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Snackbar } from '@mui/material'

import NavBar from 'Components/NavBar'
import Footer from 'Components/Footer'
import EventPage from 'Components/EventPage'
import Alert from 'Components/Alert'
import ProductPage from 'Views/Product'
import PurchasedPage from 'Views/Purchased'
import EventsPage from 'Views/Events'
import './App.css'
import { useAuthState } from 'Hooks/useGlobalState'
import '@fontsource/comfortaa'
import { useAlert } from 'Hooks'

const styles = {
  content: {
    display: 'flex',
    justifySelf: 'center',
    width: '100%',
    backgroundColor: 'floralwhite',
    '@media (minWidth: 1441px)': {
      width: '80%'
    },
    '@media (minWidth: 1921px)': {
      width: '75%'
    }
  }
}

function App() {
  const { checkIfSignedIn } = useAuthState()
  const { alertState, closeAlert, alertStatus } = useAlert()

  // Cant figure out how to get rid of eslint dep issue
  React.useEffect(() => {
    checkIfSignedIn()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => console.log({ authState }), [authState])

  return (
    <Router>
      {/* <Suspense fallback={<CircularProgress />}> */}
      <div className={`App`}>
        {/* <ToastContainer {...toastConfiguration} /> */}
        {/* <Global /> */}
        <div className='Header' style={{ zIndex: 100 }}>
          {/* <Suspense fallback={<CircularProgress />}> */}
          <NavBar />
          {/* </Suspense> */}
        </div>
        <div className={`Content`} style={styles.content}>
          {/* <Suspense fallback={<CircularProgress />}> */}
          {/* Displaying event page for now on home page */}
          <Route path='/' exact component={EventsPage} />
          <Route path='/events' exact component={EventsPage} />
          <Route path='/events/:event' exact component={EventPage} />
          {/* </Suspense> */}
          {/* <Suspense fallback={<CircularProgress />}> */}
          <Route path='/products/:productId' component={ProductPage} />
          <Route path='/purchased/:stripeData' component={PurchasedPage} />
          {/* </Suspense> */}
        </div>
        <div className='Footer'>
          <Footer />
        </div>
      </div>
      <Snackbar open={alertState} autoHideDuration={6000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity={alertStatus.severity} sx={{ width: '100%' }}>
          {alertStatus.msg}
        </Alert>
      </Snackbar>
      {/* </Suspense> */}
    </Router>
  )
}

export default App
