import * as React from 'react'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'

import { ItemLink } from 'Styles'

type Props = {
  name: string
  dateStart?: Date
  dateEnd?: Date
  description: string
  image: string
  url: string
}

const styles = {
  actionArea: {
    width: '20em',
    // height: '20em',
    borderRadius: 8,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    margin: '2em 1em'
  },
  card: {
    minWidth: 256,
    borderRadius: 8,
    boxShadow: 'none',
    '&:hover': {
      // boxShadow: `0 6px 12px 0 ${color(color).rotate(-12).darken(0.2).fade(0.5)}`
      boxShadow: `0 6px 12px 0`
    },
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    backgroundColor: '#acd8f5',
    padding: '1rem 1.5rem 1.5rem'
  },
  title: {
    fontFamily: 'Keania One',
    // fontSize: '2rem',
    color: '#fff',
    // textTransform: 'uppercase'
  },
  subtitle: {
    fontFamily: 'Montserrat',
    color: '#fff',
    opacity: 0.87,
    marginTop: '2rem',
    fontWeight: 500,
    fontSize: 14,
  },
  media: {
    height: 'inherit',
    width: 'inherit',
    backgroundSize: 'cover'
  }
}

const EventCard: React.FC<Props> = ({ name, image, description, url }) => {
  return (
    <CardActionArea sx={styles.actionArea}>
      <ItemLink to={url}>
        <Card sx={styles.card}>
          <CardMedia component='img' image={image} sx={styles.media} />
          <CardContent sx={styles.content}>
            <Typography sx={styles.title} variant={'h5'}>
              {name}
            </Typography>
            {/* <Typography sx={styles.subtitle}>{description}</Typography> */}
          </CardContent>
        </Card>
      </ItemLink>
    </CardActionArea>
  )
}

export default EventCard
