import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useRecoilValue } from 'recoil'

import { authState } from 'State'

type AxiosGet = <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>
type AxiosPost = <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T>>

type AxiosPut = <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T>>

type AxiosDelete = <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>

const useAxios = () => {
  const { idToken } = useRecoilValue(authState)
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API

  const determineAuth = (url: string) => {
    if (!url.includes('https') || url.includes('api.jkcharms.ca'))
      return {
        headers: {
          authorization: `Bearer ${idToken || ' '}`
        }
      }
    else return {}
  }

  const get: AxiosGet = (url, config?) =>
    axios.get(url, {
      ...determineAuth(url),
      ...config
    })

  const post: AxiosPost = (url, data, config?) =>
    axios.post(url, data, {
      ...determineAuth(url),
      ...config
    })

  const put: AxiosPut = (url, data, config?) =>
    axios.put(url, data, {
      ...determineAuth(url),
      ...config
    })

  const patch: AxiosPut = (url, data, config?) =>
    axios.patch(url, data, {
      ...determineAuth(url),
      ...config
    })

  const remove: AxiosDelete = (url, config?) =>
    axios.delete(url, {
      ...determineAuth(url),
      ...config
    })

  return { get, post, put, patch, remove }
}

export default useAxios
