import * as React from 'react'

import { useForm, SubmitHandler } from 'react-hook-form'

import { TextField } from 'Components/Form'
import { useFirebase } from 'Hooks'
import { SignInFields } from 'Types'
import { Grid, Button } from '@mui/material'

type Props = {
  setClose: () => void,
  cb?: (idToken: string) => void
}

const SignIn: React.FC<Props> = ({ setClose, cb }) => {
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const { handleSubmit, control } = useForm<SignInFields>()
  const { signInWithEmail } = useFirebase()

  const onSubmit: SubmitHandler<SignInFields> = async data => {
    try {
      setDisabled(true)
      const idToken = await signInWithEmail(data.email, data.password)
      cb && await cb(idToken || '')
      // const res = await signIn(data.email, data.password)
      // console.log({ res })
      setClose()
    } catch (err) {
      setDisabled(false)
      console.log(err)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        spacing={1}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        container
      >
        <TextField fieldName='email' label='Email' control={control} />
        <TextField
          fieldName='password'
          label='Password'
          // textFieldProps={{ type: "password" }}
          type='password'
          control={control}
        />
        <Grid item sx={{ m: 2}}>
          <Button variant='contained' disabled={disabled} type='submit'>Submit</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SignIn
