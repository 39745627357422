import * as React from 'react'

import { useParams } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'

type Props = {}

const PurchasedPage: React.FC<Props> = () => {
  const stripe = useStripe()
  const [message, setMessage] = React.useState<string>()
  console.log('params', useParams())

  React.useEffect(() => {
    if (!stripe) {
      return
    }


    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) return
    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        console.log('no intent', paymentIntent)
        return
      }
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Success! Payment received.')
          break

        case 'processing':
          setMessage("Payment processing. We'll update you when payment is received.")
          break

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Payment failed. Please try another payment method.')
          break

        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  React.useEffect(() => console.log(message), [message])

  // return message;
  return <></>
}

export default PurchasedPage
