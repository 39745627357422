import * as React from 'react'

import {
  Dialog,
  DialogProps,
  DialogTitle,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Theme
} from '@mui/material'

import TabPanel from 'Components/TabPanel'
import SignIn from './SignIn'
import SignUp from './SignUp'
import { Close } from '@mui/icons-material'

type Props = {
  setClose: () => void,
  cb?: (idToken: string) => void,
} & DialogProps
type State = 'Sign Up' | 'Sign In'

const SignInUp: React.FC<Props> = ({ setClose, cb, ...dialogProps }) => {
  const [state, setState] = React.useState<State>('Sign Up')
  const [value, setValue] = React.useState<number>(0)

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue)
    setState(newValue === 0 ? 'Sign Up' : 'Sign In')
  }

  const styles = {
    closeIcon: {
      float: 'right'
    },
    container: (theme: Theme) => ({
      '& .MuiPaper-root': {
        background: theme.palette.primary.light,
        [theme.breakpoints.down('sm')]: {
          width: '80%'
        },
        [theme.breakpoints.up('sm')]: {
          width: '25em'
        }
      }
    }),
    tabs: {
      '& .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    title: {
      display: 'flex',
      justifyContent: 'center'
    },
    tab: (theme: Theme) => ({
      color: theme.palette.primary.dark,
    })
  }

  return (
    <Dialog {...dialogProps} onClose={setClose} sx={styles.container} maxWidth='lg'>
      <DialogTitle>
        <IconButton sx={styles.closeIcon} onClick={setClose}>
          <Close />
        </IconButton>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
          sx={styles.tabs}
        >
          <Tab sx={styles.tab} label='Sign Up' />
          <Tab sx={styles.tab} label='Sign In' />
        </Tabs>
        <Typography variant='h3' sx={styles.title}>
          {state}
        </Typography>
      </DialogTitle>
      <TabPanel value={value} index={0}>
        <SignUp setClose={setClose} cb={cb} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SignIn setClose={setClose} cb={cb} />
      </TabPanel>
    </Dialog>
  )
}

export default SignInUp
