import { Card, styled } from '@mui/material'

const createRip = (size: number, bg: string) => ({
  width: size / 2,
  position: 'relative',
  margin: `${size / 2}px 0`,
  background: `url(${bg})`,
  backgroundSize: 'cover'
})
const createPseudoElm = (size: number) => ({
  boxSizing: 'content-box',
  display: 'block',
  content: '" "',
  width: size,
  height: size,
  borderRadius: '50%',
  position: 'absolute',
  zIndex: 1,
  borderWidth: size / 4,
  borderStyle: 'solid',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent'
})
const min = (val: number, number = 4) => (val < number ? number : val)

type RipStyles = {
  bg: string
  mainColor?: string
  lightColor?: string
  size?: number
}

const createVerticalRipStyles = ({
  bg,
  mainColor = '#000',
  lightColor = '#000',
  size = 20
}: RipStyles) => ({
  left: {
    ...createRip(size, bg),
    '&:before, &:after': {
      left: size / 2,
      ...createPseudoElm(size)
    },
    '&:before': {
      top: -size / 2,
      transform: 'translate(-50%, -50%) rotate(45deg)',
      borderBottomColor: mainColor,
      borderTopColor: 'transparent'
    },
    '&:after': {
      bottom: -size / 2,
      transform: 'translate(-50%, 50%) rotate(-45deg)',
      borderTopColor: mainColor,
      borderBottomColor: 'transparent'
    },
    '& $sheet': {
      right: 0,
      backgroundColor: mainColor
    },
    '& $tear': {
      right: 0,
      transform: 'translateX(50%)'
    }
  },
  right: {
    ...createRip(size, bg),
    '&:before, &:after': {
      ...createPseudoElm(size),
      left: 0
    },
    '&:before': {
      top: -size / 2,
      transform: 'translate(-50%, -50%) rotate(-45deg)',
      borderBottomColor: lightColor,
      borderTopColor: 'transparent'
    },
    '&:after': {
      bottom: -size / 2,
      transform: 'translate(-50%, 50%) rotate(45deg)',
      borderTopColor: lightColor,
      borderBottomColor: 'transparent'
    },
    '& $sheet': {
      left: 0,
      backgroundColor: lightColor
    },
    '& $tear': {
      left: 0,
      transform: 'translateX(-50%)'
    }
  },
  sheet: {
    width: size,
    height: '100%',
    position: 'absolute',
    overflow: 'hidden'
  },
  tear: {
    position: 'absolute',
    borderRight: `${min(size / 6)}px dotted black`,
    top: 0,
    height: '100%'
  }
})

const styles = {
  media: {
    margin: 'auto',
    width: 130,
    height: 30
  },
  label: {
    padding: '0 8px',
    fontFamily: 'comfortaa'
  },
  header: {
    fontFamily: 'comfortaa',
    fontWeight: 700
  }
}

const borderRadius = 12

const EventTicketCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  bottom: '5vh',
  right: '5vw',
  zIndex: 100,
  overflow: 'visible',
  background: 'none',
  display: 'flex',
  minWidth: 343,
  minHeight: 150,
  cursor: 'pointer',
  filter: 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3))',
  '& .moveLeft, .moveRight': {
    transition: '0.3s'
  },
  '&:hover': {
    '& .moveLeft': {
      transform: 'translateX(-8px)'
    },
    '& .moveRight': {
      transform: 'translateX(8px)'
    }
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: 400
  }
}))

const EventTicketLeft = styled('div', { shouldForwardProp: prop => prop !== 'bg' })<{
  bg: string
}>(({ theme, bg }) => ({
  borderTopLeftRadius: borderRadius,
  borderBottomLeftRadius: borderRadius,
  flexBasis: '25%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${bg})`,
  backgroundSize: 'cover',
  borderTop: '4px solid black',
  borderLeft: '4px solid black',
  borderBottom: '4px solid black'
}))

const EventTicketLeftContent = styled('div')(() => ({
  transform: 'rotate(-90deg)',
  height: '70%',
  width: '150%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const EventTicketRight = styled('div', { shouldForwardProp: prop => prop !== 'bg' })<{
  bg: string
}>(({ theme, bg }) => ({
  borderTopRightRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  background: `url(${bg})`,
  backgroundSize: 'cover',
  borderTop: '4px solid black',
  borderRight: '4px solid black',
  borderBottom: '4px solid black'
}))

export {
  createVerticalRipStyles,
  EventTicketCard,
  EventTicketRight,
  EventTicketLeft,
  EventTicketLeftContent,
  styles
}
