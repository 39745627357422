import * as React from 'react'
import { Grid, IconButton, Box } from '@mui/material'
import {
  ArrowBackIos,
  ArrowForwardIos,
  FiberManualRecord,
  FiberManualRecordOutlined
} from '@mui/icons-material'

import LazyLoadImg from 'Components/LazyLoadImg'
import styles from './styles'

type PreviewImageProps = {
  image: string
  currentIndex: number
  imageIndex: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

const PreviewImage: React.FC<PreviewImageProps> = ({
  image,
  imageIndex,
  currentIndex,
  setCurrentIndex
}) => {
  const handleClick = () => setCurrentIndex(imageIndex)

  return (
    <img
      src={image}
      alt='product preview'
      style={{
        border: currentIndex === imageIndex ? '1px solid lightblue' : undefined,
        ...styles.previewImage
      }}
      onClick={handleClick}
    />
  )
}

type CarouselControlProps = {
  totalIndex: number
  currentIndex: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

const CarouselControls: React.FC<CarouselControlProps> = ({
  totalIndex,
  setCurrentIndex,
  currentIndex
}) => {
  const handleClickForward = () => setCurrentIndex(prev => prev + 1)
  const handleClickBackward = () => setCurrentIndex(prev => prev - 1)
  const handleDotClick = (index: number) => setCurrentIndex(index)

  return (
    <Grid item xs={12} sx={styles.controlContainer}>
      <IconButton
        style={{ visibility: currentIndex === 0 ? 'hidden' : undefined }}
        sx={styles.controlButtons}
        onClick={handleClickBackward}
      >
        <ArrowBackIos />
      </IconButton>
      <Box sx={styles.controlDotsContainer}>
        {Array(totalIndex)
          .fill('_')
          .map((_, index) => (
            <IconButton
              key={`dotIcon${index}`}
              onClick={() => handleDotClick(index)}
              size='small'
              sx={styles.controlButtons}
            >
              {currentIndex === index ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
            </IconButton>
          ))}
      </Box>
      <IconButton
        style={{ visibility: currentIndex === totalIndex - 1 ? 'hidden' : undefined }}
        sx={styles.controlButtons}
        onClick={handleClickForward}
      >
        <ArrowForwardIos />
      </IconButton>
    </Grid>
  )
}

type Props = {
  images: string[]
}

const ImageCarousel: React.FC<Props> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)

  const scrollOnHover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // TODO Come back to this
    // This will scroll when you hover near bottom or top
    // Make another one that will scroll when clicking on the controls
    // const element = document.getElementById('preview-container')
    // console.log(e.clientX)
    // if (e.clientY > 350) {
    //   console.log('we going')
    //   element?.scrollTo({ top: 1, behavior: 'smooth' })
    // }
  }

  return (
    <Grid container item sx={styles.container}>
      <Grid item xs={3}>
        <Box sx={styles.previewContainer} id='preview-container' onMouseOver={scrollOnHover}>
          {images.map((image, index) => (
            <PreviewImage
              key={`previewImage${index}`}
              image={image}
              imageIndex={index}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={9} container sx={styles.mainImageContainer}>
        <CarouselControls
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          totalIndex={images.length}
        />
        <div style={styles.mainImage}>
          <LazyLoadImg />
          {/* <img
            src={images[currentIndex]}
            alt='product gallery'
            style={styles.mainImage}
          ></img> */}
        </div>
      </Grid>
    </Grid>
  )
}

export default ImageCarousel
