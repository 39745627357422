import { useRecoilState } from 'recoil'

import { alertState, alertStatus } from 'State'
import { AlertStatus } from 'Types'

const useAlert = () => {
  const [state, setState] = useRecoilState(alertState)
  const [status, setStatus] = useRecoilState(alertStatus)

  const closeAlert = () => {
    setState(false)
  }

  const setAlertStatus = (status: AlertStatus) => {
    setState(true)
    setStatus(status)
  }

  return {
    alertState: state,
    closeAlert,
    alertStatus: status,
    setAlertStatus
  }
}

export default useAlert
