import { atom, selector } from 'recoil'
import { Product, ProductItem, Currencies, Auth, BasicEvent, AlertStatus } from 'Types'

export const cartState = atom({
  key: 'cartState',
  default: false
})

export const cartItems = atom<ProductItem[]>({
  key: 'cartItems',
  default: []
})

export const currency = atom<Currencies>({
  key: 'currency',
  default: 'CAD'
})

export const prodState = atom<Product[]>({
  key: 'prodState',
  default: []
})

export const numOfItems = selector<number>({
  key: 'numOfItems',
  get: ({ get }) => {
    const items = get(cartItems)

    return items.map(item => item.amount).reduce((a, b) => a + b, 0)
  }
})

export const authState = atom<Auth>({
  key: 'auth',
  // See if If I can read if user is signed in here
  default: { isSignedIn: false }
})

export const basicEventState = atom<BasicEvent[]>({
  key: 'basicEvent',
  default: []
})

export const alertState = atom<boolean>({
  key: 'alertState',
  default: false
})

export const alertStatus = atom<AlertStatus>({
  key: 'alertStatus',
  default: {
    severity: 'success',
    msg: ''
  }
})

// const [openAlert, setOpenAlert] = React.useState<boolean>(false)

// const [alertStatus, setAlertStatus] = React.useState<AlertStatus>({
//   severity: 'success',
//   msg: ''
// })

// export const cartData = selector({
//   key: 'cartData',
//   get: ({ get }) => {
//     const items = get(cartItems)

//     if (!items) return null

//     const cart: any = {}

//     items.forEach(item => {
//       if (cart[item.productId]) cart[item.productId].amount += 1
//       else cart[item.productId] = { ...item }
//     })

//     console.log(cart)

//     return cart

//     // const prodIdsInCart: string[] = []
//     // items.forEach(item => {
//     //   if (!prodIdsInCart.includes(item.productId)) {
//     //     cart.push(item)
//     //   } else {
//     //     cart.forEach(cartItem => {
//     //       if (cartItem.productId === item.productId) {
//     //         cartItem.amount += 1
//     //       }
//     //     })
//     //   }
//     // })

//     // const { get: axiosGet } = useAxios()

//     // return await Promise.all<AxiosResponse<Product>>(
//     //   items.map(({ productId }) => axios.get(`http://localhost:5000/products/${productId}`))
//     // )

//     // console.log({ data })
//     // return data
//   }
// })
