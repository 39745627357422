import * as React from 'react'

import { useForm, SubmitHandler } from 'react-hook-form'

import { useFirebase } from 'Hooks'
import { TextField } from 'Components/Form'
import { SignUpFields } from 'Types'
import { Grid, Button, Typography } from '@mui/material'

type Props = {
  setClose: () => void
  cb?: (idToken: string) => void
}

const SignUp: React.FC<Props> = ({ setClose, cb }) => {
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [errorState, setErrorState] = React.useState<string>('')
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<SignUpFields>()
  const { signUpWithEmail } = useFirebase()

  const onSubmit: SubmitHandler<SignUpFields> = async data => {
    if (!data || !data.email || !data.password) return
    if (data.password !== data.secondPassword) return setErrorState('Passwords must match')

    try {
      setDisabled(true)
      const idToken = await signUpWithEmail(data.email, data.password)
      cb && (await cb(idToken || ''))

      setClose()
    } catch (e) {
      console.log(e)
      setDisabled(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        spacing={1}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        container
      >
        <TextField
          fieldName='email'
          label='Email'
          control={control}
          gridProps={{ xs: 12, md: 8 }}
          controllerProps={{ rules: { required: true } }}
        />
        {errors.email?.type === 'required' && (
          <Typography color='red' variant='subtitle1'>
            Email is required
          </Typography>
        )}
        <TextField
          fieldName='password'
          label='Password'
          type='password'
          control={control}
          gridProps={{ xs: 12, md: 8 }}
          controllerProps={{ rules: { minLength: 6 } }}
        />
        {errors.password?.type === 'minLength' && (
          <Typography color='red' variant='subtitle1'>
            Password must be atleast 6 characters long
          </Typography>
        )}
        <TextField
          fieldName='secondPassword'
          label='Re-Enter Password'
          type='password'
          control={control}
          gridProps={{ xs: 12, md: 8 }}
          controllerProps={{ rules: { minLength: 6 } }}
        />
        {errors.secondPassword?.type === 'minLength' && (
          <Typography color='red' variant='subtitle1'>
            Password must be atleast 6 characters long
          </Typography>
        )}
        {errorState && (
          <Typography color='red' variant='subtitle1'>
            {errorState}
          </Typography>
        )}
        <Grid item sx={{ m: 2 }}>
          <Button variant='contained' disabled={disabled} type='submit'>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SignUp
