import * as React from 'react'

import { Grid, Typography, Button, CircularProgress } from '@mui/material'
import { Product } from 'Types'
import { useCartState } from 'Hooks/useGlobalState'

const styles = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '2em 2em 0em'
  },
  headerPrice: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '2em'
  }
}

type Props = {
  product: Product
}

const mockProduct: Product = {
  name: 'Kpop Music Box',
  price: {
    base: 5
  },
  category: 'KPop',
  productId: '2',
  description: 'dabfaf',
  tags: ['tasd'],
  quantity: 10,
  images: []
}

const ProductHeader: React.FC<Props> = ({ product }) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={styles.headerContainer}>
        <Typography variant='h4'>{product.name}</Typography>
      </Grid>
      <Grid item xs={12} sx={styles.headerPrice}>
        <Typography variant='h3'>CA ${product.price.base}</Typography>
      </Grid>
    </Grid>
  )
}

const ProductPurchase: React.FC<Props> = ({ product }) => {
  const { setCartItems } = useCartState()

  return (
    <Grid container>
      <Grid item xs={4}>
        <Button variant='contained' onClick={() => setCartItems({ ...product, amount: 1 })}>
          Add to Cart
        </Button>
        <Button variant='contained'>Buy Now</Button>
      </Grid>
    </Grid>
  )
}

const ProductDetails: React.FC<Props> = ({ product }) => {
  // React.useEffect(() => {}) Run on load to get product details

  return (
    <Grid container>
      <Grid item xs={12}>
        <ProductHeader product={mockProduct} />
      </Grid>
      <Grid item xs={12}>
        <React.Suspense fallback={<CircularProgress />}>
          <ProductPurchase product={product} />
        </React.Suspense>
      </Grid>
    </Grid>
  )
}

export default ProductDetails
